<template>
<loader :loading="loading">
    <form>
        <columns>
            <column>
                <text-input
                    required
                    classes="is-rounded is-medium"
                    description="A descriptive name for this report"
                    :error="$root.errors.name"
                    :value="report.name"
                    @input="updateName">
                    Name
                </text-input>
            </column>
            <column>
                <select-input
                    required
                    :error="$root.errors.type"
                    :items="types"
                    classes="is-rounded is-medium"
                    description="Select the type of report"
                    :value="report.type"
                    @input="updateType">
                    Type
                </select-input>
            </column>
            <column class="is-narrow">
                <select-input 
                    required 
                    :items="ranges" 
                    :value="report.range" 
                    @input="updateRange"
                    :error="$root.errors.range" classes="is-medium is-rounded"
                    description="Define the range in which this report is generated">
                    Date Range
                </select-input>
            </column>
        </columns>
        <columns>
            <column>
                <columns>
                    <column>
                        <data-selector 
                            multiple
                            searchable
                            :debounce="500"
                            classes="is-rounded is-medium"
                            value-key="id"
                            label-key="name"
                            prompt-label="All"
                            tag-class="is-medium is-rounded"
                            delete-button-class="is-medium is-rounded is-danger"
                            :value="report.customers" 
                            @input="setCustomers" 
                            :items="customer_list"
                            :description="report.customers.length ? 'This report will be scoped to the selected customers' : 'All customers will be included'">
                            Select Customers
                        </data-selector>
                    </column>
                </columns>
            </column>
            <column>
                <columns>
                    <column>
                        <data-selector  
                            multiple 
                            classes="is-rounded is-medium" 
                            value-key="id"
                            label-key="name" 
                            prompt-label="All"
                            searchable
                            tag-class="is-medium is-rounded"
                            delete-button-class="is-medium is-rounded is-danger"
                            :value="report.locations" 
                            @input="setLocations" 
                            :items="availableLocations"
                            :description="report.locations.length ? 'This report will be scoped to the selected locations' : 'All locations will be included'">
                            Select Locations
                        </data-selector>
                    </column>
                </columns>
            </column>
        </columns>
        <columns>
            <column>
                <select-input
                    required
                    :items="areas"
                    :error="$root.errors.area"
                    classes="is-rounded is-medium"
                    description="A list of statuses to include"
                    prompt-label="All"
                    :value="report.area"
                    @input="updateArea">
                    Status
                </select-input>
            </column>
            <column>
                <data-selector
                    required
                    multiple
                    searchable
                    tag-class="is-rounded is-medium"
                    delete-button-class="is-rounded is-medium is-danger"
                    classes="is-rounded is-medium"
                    description="Available in XLSX and PDF"
                    :error="$root.errors.formats"
                    :value="report.formats"
                    @input="updateFormats"
                    :items="formats">
                    Formats
                </data-selector>
            </column>
        </columns>
        <columns>
            <column>
                <text-area
                    :value="report.intro"
                    @input="updateIntro">Introduction</text-area>
            </column>
        </columns>
        
        <columns v-if="report.type">
            <column>
                <component 
                    :is="`report-builder-meta-${report.type}`"
                />
            </column>
        </columns>

        <columns>
            <column>
                <submit-button
                    :working="working"
                    @submit="createReport">
                    Save
                </submit-button>
            </column>
        </columns>
    </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import flatMap from 'lodash/flatMap'

export default {

    data: () => ({
        loading: false,
        working: false,
        types: [
            { value: 'equipment', label: 'Equipment Summary' },
            { value: 'checklist', label: 'Checklist Summary' },
        ],
        areas: [
            { value: 'progress', label: 'Progress' },
            { value: 'work_order_failure', label: 'Work Order Failures', for: ['user', 'team'] },
            { value: 'work_order_passes', label: 'Work Order Passes' },
            { value: 'volume', label: 'Volume' },
        ],
        ranges: [
            { value: 'day', label: 'Daily' },
            { value: 'week', label: 'Weekly' },
            { value: 'month', label: 'Monthly' },
            // { value: '6_months', label: '6 Months' },
            // { value: 'annual', label: 'Annual' },
        ]
    }),

    async beforeCreate() {
        await this.$store.dispatch('customer/loadCustomerList')
        this.loading = false
    },

    beforeDestroy() {
        this.clearReport()
    },

    methods: {
        ...mapMutations('report', [
            'updateName',
            'updateType',
            'updateArea',
            'updateFormats',
            'updateCustomers',
            'toggleIncludeAllCustomers',
            'setIncludeAllCustomers',
            'setIncludeAllLocations',
            'toggleIncludeAllLocations',
            'updateLocations',
            'clearReport',
            'updateIntro',
            'updateRange'
        ]),
        setCustomers(customers) {
            this.updateCustomers(customers)

            if(this.report.customers.length) {
                this.setIncludeAllCustomers(false)
            } else {
                this.setIncludeAllCustomers(true)
            }
        },
        setLocations(locations) {
            this.updateLocations(locations)

            if(this.report.locations.length) {
                this.setIncludeAllLocations(false)
            } else {
                this.setIncludeAllLocations(true)
            }
        },
        createReport() {
            this.working = true
            this.$store.dispatch('report/create').then(() => {
                this.$toast.success('Report Created')
                this.working = false
                this.$router.back()
            }).catch(() => this.working = false)
        }
    },

    computed: {
        ...mapGetters('report', [
            'report',
            'formats'
        ]),
        ...mapGetters('customer', [
            'customer_list'
        ]),
        availableLocations() {
            const customer_list = this.report.customers.length === 0
                ? this.customer_list 
                : this.customer_list.filter(customer => this.report.customers.indexOf(customer) > -1)

            return flatMap(
                customer_list, 
                customer => customer.locations
            )
        }
    }

}
</script>